// 仅支持 chrome 滚动条样式

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 5px;
  background-color: #fff;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ccc;
}

.base-style {
  background-color: #fff;
  margin-bottom: 1rem;
  padding: 1.5rem;
  border-radius: 5px;
  box-shadow: 0 2px 13px 0 rgba(228, 228, 228, 0.6);
}

// 全局表格样式
%center {
  display: flex;
  justify-content: center;
  align-items: center;
}

%border-content {
  border-right: 1px solid rgba(0, 0, 0, 0.06);
}

%border-right-none {
  border-right: none;
}

%title {
  color: #333;
  font-weight: bold;
}

.loading {
  display: flex;
  width: 100%;
  height: 300px;
  align-items: center;
  justify-content: center;
}

.on-right {
  text-align: right;
}

.title {
  @extend %title;
}

.list-title {
  border: 1px solid rgba(0, 0, 0, 0.06);

  :last-child {
    @extend %border-right-none;
  }

  div {
    @extend %title;
    @extend %border-content;
    @extend %center;
  }
}

.list-content {
  > div:first-child {
    padding: 0;
  }

  img {
    height: 50px;
    width: 50px;
  }
}

.list-box {
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-top: none;

  :last-child {
    @extend %border-right-none;
  }

  > div {
    @extend %center;
    @extend %border-content;
  }

  button {
    margin: 0 5px;
  }
}

.on-right {
  text-align: right;
}

.title {
  color: #333;
  font-weight: bold;
}

.no-padding {
  padding: 0;
}

.check-content {
  margin-bottom: 24px;
  .must::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }

  .check-group {
    display: flex;
    flex-wrap: nowrap;

    > div {
      width: 10%;
      align-items: center;
    }
  }
}
.modalList{
  .list-title{
    border-bottom: none !important;
  }
}
.btn-margin{
  margin-right: 10px;
}
.max-width{
  width: 100%;
}
.list-modal{
  .list-title{
    margin: 0 !important;
  }
  .ant-list-header{
    border-bottom: none !important;
  }
}
